<template>
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 391.21 197.24"
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="98.41"
        y1="56.58"
        x2="132.7"
        y2="56.58"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset="0.07" stop-color="#a4ddab" />
        <stop offset="0.5" stop-color="#3db64e" />
        <stop offset="1" stop-color="#39b54a" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="98.41"
        y1="89.07"
        x2="132.85"
        y2="89.07"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stop-color="#fff" />
        <stop offset="0.38" stop-color="#39b54a" />
        <stop offset="1" stop-color="#00aeef" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="132.85"
        y1="89.07"
        x2="164.61"
        y2="89.07"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.17" stop-color="#01aeec" />
        <stop offset="1" stop-color="#39b54a" />
      </linearGradient>
    </defs>
    <text class="cls-1" transform="translate(164.11 105.26)">
      SSmosis
      <tspan class="cls-2" x="182.75" y="0"></tspan>
    </text>
    <path class="cls-3" d="M132.18,39.93c18,0,31.93,14.59,31.93,32.6" />
    <path
      class="cls-4"
      d="M66.32,42c-18,0-32.6,13.68-32.6,30.57s14.59,30.56,32.6,30.56S98.91,89.41,98.91,72.53"
    />
    <path class="cls-5" d="M132.85,105.12a31.26,31.26,0,1,1-31.25,31.25" />
    <path class="cls-6" d="M98.91,73.72A33.79,33.79,0,0,1,132.7,39.93" />
    <path class="cls-7" d="M98.91,72.53c0,18,15.94,32.59,33.94,32.59" />
    <path class="cls-8" d="M132.85,105.12c16.15,0,31.26-14.59,31.26-32.59" />
  </svg>
</template>

<style scoped>
.cls-1 {
  font-size: 45.06px;
  fill: #fff;
  font-family: Montserrat-Thin, Montserrat;
  font-weight: 200;
}
.cls-2 {
  letter-spacing: 0.49em;
}
.cls-3,
.cls-4,
.cls-5,
.cls-6,
.cls-7,
.cls-8 {
  fill: none;
  stroke-miterlimit: 10;
}
.cls-3 {
  stroke: #39b54a;
}
.cls-4 {
  stroke: #fff;
}
.cls-5 {
  stroke: #00aeef;
}
.cls-6 {
  stroke: url(#linear-gradient);
}
.cls-7 {
  stroke: url(#linear-gradient-2);
}
.cls-8 {
  stroke: url(#linear-gradient-3);
}
</style>
